











































import { Component, Vue, Watch } from "vue-property-decorator";

import { catVue } from "@/util/logging";
import { VerlagListeModel } from "@/models/VerlagListeModel";

// eslint-disable-next-line

@Component({})
export default class VerlagSelector extends Vue {
  value = 0;
  model = null as null | VerlagSelector;
  descriptionLimit = 60;
  entries = [] as VerlagListeModel[];
  isLoading = false;
  search = null;
  count = 0;

  created(): void {
    catVue.info(`${this.$options.name}:: CREATED ... ${this.value}`);
  }

  @Watch("search")
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async searchChanged(val: string): Promise<boolean> {
    // Items have already been loaded
    if (this.entries.length > 0) return true;

    // Items have already been requested
    if (this.isLoading) return false;
    this.isLoading = true;

    try {
      const items = await VerlagListeModel.getList();
      this.entries = items;
      this.count = items.length;
      this.isLoading = false;

      return true;
    } catch (e) {
      catVue.error(`Fehler beim Laden der VerlagsListe ... ${e}`, null);
    }

    return false;
  }
}
